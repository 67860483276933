import React, { useEffect } from 'react';
import SEO from '../seo';
import { Container, Box, Tabs, Tab, Typography } from '@material-ui/core';
import VerifyEmail from './verify-email';
import AccountInformation from './account-information';
import Licenses from './licences';
import Simulate from './simulate';
import Admin from './admin';
import useAuth from '../../State/Auth/useAuth';
import { PageRendererProps } from 'gatsby';

interface AllyProps {
  id: string;
  'aria-controls': string;
}

const a11yProps = (index: number): AllyProps => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

interface AccountProps {
  location: PageRendererProps['location'];
}

const Account = ({ location }: AccountProps): JSX.Element | null => {
  const { initialized, firebaseUser, admin, user, requestGetUser, getUser } = useAuth();

  // const [value, setValue] = useState(0);

  const value = ['#organization'].includes(location.hash)
    ? 1
    : ['#admin'].includes(location.hash)
    ? 2
    : 0;

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number): void => {
    switch (newValue) {
      case 1:
        window.location.hash = 'organization';
        break;
      case 2:
        window.location.hash = 'admin';
        break;
      default:
        window.location.hash = '';
        break;
    }
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }
    if (firebaseUser) {
      getUser();
    }
  }, [firebaseUser, initialized]);

  if (!firebaseUser || !initialized) return null;

  const emailVerified = firebaseUser && firebaseUser.emailVerified;

  return (
    <>
      <SEO title="Login" />
      <Container maxWidth="md">
        <Box mt={2} mb={2}>
          <>
            {emailVerified && (
              <Tabs
                indicatorColor="primary"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Account tabs"
                scrollButtons="auto"
              >
                <Tab label="General" {...a11yProps(0)} />
                <Tab label="Licenses" {...a11yProps(1)} />
                {admin && <Tab label="Admin" {...a11yProps(2)} />}
              </Tabs>
            )}
            <TabPanel value={value} index={0}>
              <VerifyEmail />
              <AccountInformation />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Licenses loading={requestGetUser.loading} user={user} />
              <Simulate />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Admin />
            </TabPanel>
          </>
        </Box>
      </Container>
    </>
  );
};

export default Account;
