import React, { useCallback } from 'react';
import { Button, Card, CardContent, Box, Typography, CardActions } from '@material-ui/core';
import useAuth from '../../State/Auth/useAuth';
import { Alert } from '@material-ui/lab';

const VerifyEmail = () => {
  const { firebaseUser, requestSendEmailVerification, sendEmailVerification } = useAuth();

  if (firebaseUser && firebaseUser.emailVerified) {
    return null;
  }

  const sendVerificationEmail = useCallback(() => sendEmailVerification(), []);

  return (
    <Box mt={2} mb={2}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Email has not been verified yet
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Email must be verified in order to use the software.
          </Typography>
          {requestSendEmailVerification.errorMessage && (
            <Box mt={2} width="100%">
              <Alert severity="error">{requestSendEmailVerification.errorMessage}</Alert>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="secondary"
            disabled={requestSendEmailVerification.loading}
            onClick={sendVerificationEmail}
          >
            Resend verification email
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default VerifyEmail;
