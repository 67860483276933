import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  TableContainer,
  CircularProgress,
} from '@material-ui/core';

import TablePagination from '@material-ui/core/TablePagination';
import AdminUsersDialog from './AdminUsersDialog';
import { User } from '../../../@types/user';
import useRedis from '../../../State/Redis/useRedis';
import usePagination from '../../../hooks/usePagination';

interface Column {
  id: 'email' | 'uid' | 'adminOnOrganizations' | 'organizations';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'uid', label: 'uid' },
  { id: 'adminOnOrganizations', label: 'Org Admin' },
  { id: 'organizations', label: 'Org' },
];

const UsersTable = ({}) => {
  const { usersData, requestGetUser, adminGetUsers } = useRedis();
  const { pagination, onPageChange, onPageRowChange } = usePagination(usersData);
  const [editUser, setEditUser] = useState<User | undefined>();

  useEffect(() => {
    adminGetUsers(pagination.rowsPerPage, pagination.startAfter, pagination.current);
  }, [pagination]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    onPageChange(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onPageRowChange(+event.target.value);
  };

  if (requestGetUser.loading) {
    return <CircularProgress />;
  }

  if (!usersData) {
    return null;
  }

  return (
    <Box mt={2} mb={2}>
      <Typography variant="h5" component="h2">
        Accounts
      </Typography>
      <Box mt={2} mb={2}>
        <Paper>
          {editUser && (
            <AdminUsersDialog user={editUser} onClose={(): void => setEditUser(undefined)} />
          )}
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData.users.map((user) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={user.uid || user.email}
                    onClick={(): void => setEditUser(user)}
                  >
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell>
                      {!!user.organizations?.[0] &&
                      user.adminOnOrganizations?.[0] &&
                      user.adminOnOrganizations[0].localeCompare(user.organizations[0]) === 0
                        ? 'Yes'
                        : '-'}
                    </TableCell>
                    <TableCell>{user.organizations?.[0] || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[1, 10, 25, 100]}
            component="div"
            count={usersData.size}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};

export default UsersTable;
