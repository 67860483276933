import React, { SyntheticEvent, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ButtonProgressContainer from './form/button-progress-container';
import CircularProgressOverlay from './form/circular-progress-overlay';

import DialogTitle from '../dialog/dialog-title';
import useAuth from '../../State/Auth/useAuth';
import useSessionRequestStatus from '../../hooks/useSessionRequestStatus';

interface AccountChangePasswordDialogProps {
  onClose: () => void;
}

const AccountChangePasswordDialog = ({
  onClose,
}: AccountChangePasswordDialogProps): JSX.Element => {
  const { requestUpdatePassword, updatePassword } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const _requestUpdatePassword = useSessionRequestStatus(requestUpdatePassword);

  const onChangePassword = useCallback(() => updatePassword(password), [password, updatePassword]);

  return (
    <>
      <Dialog open={!_requestUpdatePassword.successful} fullWidth>
        <DialogTitle onClose={onClose}>Change password</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Please enter a new password below</DialogContentText>
          <FormControl fullWidth error={!!_requestUpdatePassword.errorMessage} margin="none">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              fullWidth
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              name="password"
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
                setPassword(e.currentTarget.value)
              }
              error={!!_requestUpdatePassword.errorMessage}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(): void => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {_requestUpdatePassword.errorMessage && (
              <Typography variant="body2" color="textSecondary" component="p">
                {_requestUpdatePassword.errorMessage}
              </Typography>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonProgressContainer>
            <Button
              disabled={_requestUpdatePassword.loading}
              color="primary"
              onClick={onChangePassword}
            >
              Change
            </Button>
            {_requestUpdatePassword.loading && <CircularProgressOverlay size={30} />}
          </ButtonProgressContainer>
        </DialogActions>
      </Dialog>
      <Dialog open={_requestUpdatePassword.successful} fullWidth>
        <DialogTitle onClose={onClose}>Password changed</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Password was successfully changed.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountChangePasswordDialog;
