import { useEffect, useState } from 'react';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  RequestError,
  requestIdle,
} from '../utils/request-state';
import usePrevious from './usePrevious';

const useSessionRequestStatus = (request: RequestState): RequestState => {
  const [_request, setRequest] = useState(() => requestIdle());
  const previousRequest = usePrevious(request);

  useEffect(() => {
    if (request.loading) {
      setRequest(requestInit());
    }
  }, [request.loading]);

  useEffect(() => {
    if (!!previousRequest?.loading && !request.loading && request.successful) {
      setRequest(requestSuccessful());
    }

    if (!!previousRequest?.loading && !request.loading && request.error) {
      const err: RequestError | undefined =
        request.errorCode && request.errorMessage
          ? {
              code: request.errorCode,
              message: request.errorMessage,
            }
          : undefined;

      setRequest(requestError(err));
    }
  }, [previousRequest?.loading, request]);

  return _request;
};

export default useSessionRequestStatus;
