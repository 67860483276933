import React from 'react';
import { Box, Card, CardContent, Typography, CardActions, Grid } from '@material-ui/core';
import SimulateLogin from './simulate-login';

const Simulate = (): JSX.Element => {
  const [loadingSimulate, setLoadingSimulate] = React.useState<{ [key: string]: boolean }>({
    a: false,
    b: false,
    c: false,
  });
  return (
    <Box mt={2} mb={2}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Simulate
          </Typography>
        </CardContent>
        <CardActions>
          <SimulateLogin
            id="a"
            loadingSimulate={loadingSimulate}
            setLoadingSimulate={setLoadingSimulate}
          />
          <SimulateLogin
            id="b"
            loadingSimulate={loadingSimulate}
            setLoadingSimulate={setLoadingSimulate}
          />
          <SimulateLogin
            id="c"
            loadingSimulate={loadingSimulate}
            setLoadingSimulate={setLoadingSimulate}
          />
        </CardActions>
      </Card>
    </Box>
  );
};

export default Simulate;
