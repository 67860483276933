import React, { SyntheticEvent, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Input,
  Typography,
} from '@material-ui/core';
import ButtonProgressContainer from '../form/button-progress-container';
import CircularProgressOverlay from '../form/circular-progress-overlay';

import { User } from '../../../@types/user';
import DialogTitle from '../../dialog/dialog-title';
import useAuth from '../../../State/Auth/useAuth';

interface RequestState {
  loading: boolean;
  successful: boolean;
  error: boolean;
  errorCode: string;
  errorMessage: string;
}

interface Licenses {
  exclusiveLicenses: string;
  manuallySubscribedLicenses: string;
}

const createUsernameRequest = (token: string, uid: string, licenses: Licenses): Promise<Response> =>
  // fetch('/.netlify/functions/admin-update-user', {
  fetch(`${process.env.GATSBY_FUNCTIONS_URL}/adminUpdateUser`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      uid,
      exclusiveLicenses: parseInt(licenses.exclusiveLicenses, 10),
      manuallySubscribedLicenses: parseInt(licenses.manuallySubscribedLicenses, 10),
    }),
  });

interface UsernameCreateDialog {
  user: User;
  onClose: () => void;
}

const AdminUsersDialog = ({ user, onClose }: UsernameCreateDialog) => {
  const auth = useAuth();
  const [licenses, setLicenses] = useState<Licenses>({
    exclusiveLicenses: '0',
    manuallySubscribedLicenses: '0',
    /*
    exclusiveLicenses: `${user.exclusiveLicenses}` || '0',
    manuallySubscribedLicenses: `${user.manuallySubscribedLicenses}` || '0',
    */
  });

  const [request, setRequest] = useState<RequestState>({
    loading: false,
    successful: false,
    error: false,
    errorCode: '',
    errorMessage: '',
  });

  const onChange = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.currentTarget;
    setLicenses({
      ...licenses,
      [name]: value.replace(/[^0-9]/g, ''),
    });
  };

  const onBlur = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.currentTarget;
    if (typeof value === 'string' && !value) setLicenses({ ...licenses, [name]: '0' });
  };

  const onUpdateUser = async () => {
    setRequest({
      error: false,
      successful: false,
      errorCode: '',
      errorMessage: '',
      loading: true,
    });
    const token = await auth.getToken();
    if (token) {
      const result = await createUsernameRequest(token, user.uid, licenses);
      if (result.status === 200) {
        setRequest({
          error: false,
          successful: true,
          errorCode: '',
          errorMessage: '',
          loading: false,
        });
      } else if (result.status === 400) {
        const json = await result.json();
        setRequest({
          error: true,
          successful: false,
          errorCode: json.code,
          errorMessage: json.message,
          loading: false,
        });
      } else {
        setRequest({
          error: true,
          successful: false,
          errorCode: '',
          errorMessage: 'An error has occured, please try again',
          loading: false,
        });
      }
    }
  };
  const requestError = !request.errorCode ? request.errorMessage : undefined;

  return (
    <>
      <Dialog open={!request.successful} fullWidth>
        <DialogTitle onClose={onClose}>Edit user</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Please select quantity below</DialogContentText>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="standard-adornment-password">Exclusive Licenses</InputLabel>
            <Input
              fullWidth
              type="number"
              name="exclusiveLicenses"
              value={licenses.exclusiveLicenses}
              onChange={onChange}
              disabled={request.loading}
              onBlur={onBlur}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="standard-adornment-password">Manually Subscribed</InputLabel>
            <Input
              fullWidth
              type="number"
              name="manuallySubscribedLicenses"
              value={licenses.manuallySubscribedLicenses}
              onChange={onChange}
              disabled={request.loading}
              onBlur={onBlur}
            />
            {request.error && (
              <Typography variant="body2" color="textSecondary" component="p">
                {request.error}
              </Typography>
            )}
          </FormControl>
          {requestError && (
            <Typography variant="body2" color="textSecondary" component="p">
              {requestError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonProgressContainer>
            <Button disabled={request.loading} color="primary" onClick={onUpdateUser}>
              Update
            </Button>
            {request.loading && <CircularProgressOverlay size={30} />}
          </ButtonProgressContainer>
        </DialogActions>
      </Dialog>
      <Dialog open={request.successful} fullWidth>
        <DialogTitle onClose={onClose}>User updated</DialogTitle>
        <DialogContent>
          <DialogContentText>yes</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminUsersDialog;
