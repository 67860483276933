import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core';

import { RedisAccount } from '../../../@types/Redis';

interface Column {
  id: 'uid' | 'active';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'uid', label: 'Id', minWidth: 170 },
  { id: 'active', label: 'Active' },
];

interface MetricsRedisTableProps {
  accounts: RedisAccount[];
}

const MetricsRedisTable = ({ accounts }: MetricsRedisTableProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts?.map((item) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={item.uid}>
                <TableCell>{item.uid}</TableCell>
                <TableCell>{item.active}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MetricsRedisTable;
