import { useCallback, useState } from 'react';

interface PaginationData {
  size: number;
  next: string;
  current: string;
  previous: string;
}

const usePagination = (paginationData?: PaginationData) => {
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 25,
    startAfter: '',
    current: '',
  });

  const onPageChange = useCallback(
    (page: number) => {
      if (paginationData) {
        setPagination({
          ...pagination,
          page,
          startAfter: page > pagination.page ? paginationData.next : paginationData.previous,
          current: pagination.startAfter,
        });
      }
    },
    [paginationData, pagination]
  );

  const onPageRowChange = useCallback((rowsPerPage: number) => {
    setPagination({
      page: 0,
      rowsPerPage,
      startAfter: '',
      current: '',
    });
  }, []);

  return { pagination, onPageChange, onPageRowChange };
};

export default usePagination;
