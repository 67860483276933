import React from 'react';
import { Typography, Button } from '@material-ui/core';
import useAuth from '../../../State/Auth/useAuth';

const getResult = (token: string, id: string): Promise<Response> =>
  // fetch('/.netlify/functions/sign-in', {
  fetch(`${process.env.GATSBY_FUNCTIONS_URL}/signIn`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ id }),
  });

interface SimulateLogin {
  id: string;
  loadingSimulate: { [key: string]: boolean };
  setLoadingSimulate: (loading: { [key: string]: boolean }) => void;
}

interface ErrorResponse {
  message: string;
}

const SimulateLogin = ({ id, loadingSimulate, setLoadingSimulate }: SimulateLogin): JSX.Element => {
  const auth = useAuth();
  const [error, setError] = React.useState<ErrorResponse | undefined>();
  const simulateLoginOnProgram = async (id: string): Promise<void> => {
    setError(undefined);
    setLoadingSimulate({
      ...loadingSimulate,
      [id]: true,
    });

    const token = await auth.getToken();
    if (token) {
      const result = await getResult(token, id);
      if (result.status !== 200) {
        const error = await result.json();
        setError(error);
      }
    }
    setLoadingSimulate({
      ...loadingSimulate,
      [id]: false,
    });
  };

  return (
    <>
      <Button
        color="primary"
        size="small"
        disabled={loadingSimulate[id]}
        onClick={(): Promise<void> => simulateLoginOnProgram(id)}
      >
        login {id}
      </Button>
      {error && <Typography>{error.message}</Typography>}
    </>
  );
};

export default SimulateLogin;
