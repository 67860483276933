import { useContext } from 'react';
import { RedisContext, RedisContextProps } from './RedisContext';

const useRedis = (): RedisContextProps => {
  const context = useContext(RedisContext);

  if (!context) {
    throw new Error('useRedis must be used within a RedisProvider');
  }

  return context;
};
export default useRedis;
