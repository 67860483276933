import React, { useState } from 'react';
import List from '@material-ui/core/List';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { CardContent, Card, Typography, CardActions, Button, Box } from '@material-ui/core';

import AccountChangePasswordDialog from './accountChangePasswordDialog';
import AccountListItem from './AccountListItem';
import useAuth from '../../State/Auth/useAuth';

const AccountInformation = () => {
  const { firebaseUser } = useAuth();
  const [changePassword, setChangePassword] = useState(false);
  return (
    <Box mt={2} mb={2}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Account information
          </Typography>
          <List>
            <AccountListItem
              primary="Name"
              secondary={firebaseUser?.displayName}
              loading={!firebaseUser}
              skeletonWidth="100px"
              avatar={<AccountCircleIcon />}
            />
            <AccountListItem
              primary="Email"
              secondary={firebaseUser?.email}
              loading={!firebaseUser}
              skeletonWidth="100px"
              avatar={<EmailIcon />}
            />
            <AccountListItem
              primary="Email verified"
              secondary={firebaseUser?.emailVerified ? 'Yes' : 'No'}
              loading={!firebaseUser}
              skeletonWidth="100px"
              avatar={<VerifiedUserIcon />}
            />
          </List>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={(): void => setChangePassword(!changePassword)}
          >
            Change password
          </Button>
        </CardActions>
      </Card>
      {changePassword && (
        <AccountChangePasswordDialog onClose={(): void => setChangePassword(false)} />
      )}
    </Box>
  );
};

export default AccountInformation;
