import React, { useEffect } from 'react';

import MetricsRedisTable from './MetricsRedisTable';
import MetricsRedisSummary from './MetricRedisSummary';
import { Typography, Box, CircularProgress } from '@material-ui/core';
import useRedis from '../../../State/Redis/useRedis';

const UsersTable = () => {
  const { accounts, requestGet, adminGetStatus } = useRedis();

  useEffect(() => {
    adminGetStatus();
  }, []);

  if (requestGet.loading) {
    return <CircularProgress />;
  }

  if (!accounts) {
    return <p>no data</p>;
  }

  return (
    <Box mt={2} mb={2}>
      <Typography variant="h5" component="h2">
        Metrics - Redis
      </Typography>
      <MetricsRedisSummary accounts={accounts} />
      <MetricsRedisTable accounts={accounts} />
    </Box>
  );
};

export default UsersTable;
