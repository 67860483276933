import { PageRendererProps } from 'gatsby';
import React from 'react';
import useRequireAuth from '../auth/useRequireAuth';
import Account from '../components/account';

const AccountPage = ({ location }: PageRendererProps): JSX.Element | null => {
  useRequireAuth();
  return <Account location={location} />;
};

export default AccountPage;
